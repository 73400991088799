<template>
	<div class="modal fade" id="dispatch-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle">Update Status to DISPATCHED</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form @submit.prevent="confirm">
				<div class="modal-body">
					
					
						<template  v-if="details">
							<div class="pl-3 pr-3 pb-3"> 
								<h5>
									Booking #{{details.booking_no}}
								</h5> 
								<CRow class="mb-2">
									<CCol lg="6">
											<small class="mr-2">Delivery Date</small>
											<CInput
											disabled
											:value="moment.unix(details.delivery_date).format('MMMM DD, YYYY')" 
											type="text"
											class="mb-2"
											/>
									</CCol>	
									
									<CCol lg="6">
											<small class="mr-2">Vehicle No.</small>
											<CInput
											disabled
											:value="details.vehicle.plate_no" 
											type="text"
											class="mb-2"
											/>
									</CCol>		
									
								</CRow>
								<hr/>
								

							
								<CRow>
									
									<CCol lg="12">
										<div class="form-group"> 
											<label class="mb-1">Start/Dispatch Date Time <span class="text-danger">*</span> </label>
											<Datetime format="MM/DD/YYYY H:i" v-model="bookingParams.dispatch_datetime" :required="true"></Datetime>
										</div>
										
									</CCol> 
								</CRow> 
								
								<CRow>
									<CCol lg="12">
										<label class="mb-1">Fuel PO</label>
										<CInput
											v-model="bookingParams.fuel_po"
											
											/>
									</CCol> 
								</CRow> 
								
								<CRow>
									<CCol lg="12">
										<label class="mb-1">Odometer Reading 
											<i class="fa fa-question-circle ml-1" title="Reading should be maximum of 6 characters." ></i>
										</label>
										<CInput
											v-model="bookingParams.odometer" 
												type="number"
												required
												v-mask="'######'"
											
											/>

											<div  class="mb-2" style="margin-top: -10px; font-size:11px " >
														<span>Current Odometer: {{ odometer_value }}
														</span>
											</div>
											<div  class="mb-2" style="margin-top: -5px; font-size:11px " >
												<span class="text-danger">{{message}}</span>
											</div>
									</CCol> 
								</CRow> 
								<CRow>
									<CCol lg="12">
										<label class="mb-1">Remarks </label>
										<CInput
											v-model="bookingParams.remarks" 
											/>
									</CCol> 
								</CRow> 
								
									
							</div>
						</template>
				</div>
				<div class="modal-footer">
					<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="cancel">
						Cancel
					</CButton>
					<CButton size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right" type="submit">
						Confirm
					</CButton> 
					
				</div>
			</form>
			</div>
	  	</div>
	  </div>
</template>
<script>
	import config from '../../config.js';
	import axios from '../../axios';
	import Swal from 'sweetalert2'; 
	import Datetime from 'vuejs-datetimepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	import '../../style.css';
	import moment from 'moment';

	export default{
		mounted(){  
			this.modal = document.getElementById('dispatch-modal');

		},

		props: {
			showModal: false,
			// details: {
			// 	remarks: "",
			// 	booking_no: "",
			// 	plate_no: ""
			// }
		},
		components: {Datetime},
		data(){
			return{
				odometer_value:0, 
				dataParams: { 
					booking_id: ""
				},
				temp: {
					odometer: ""
				},
				bookingParams: {
					remarks: "",
					fuel_po: "", 
					dispatch_datetime: moment().format('MM/DD/YYYY HH:mm'),
					odometer: "0"
				},
				modalState: false,
				moment,
				message: "",
				details: null,
				modal: null
			}
		},
		watch: {
			details: {
				deep: true,
				handler(){
					this.bookingParams.remarks = this.details.remarks; 
					this.bookingParams.fuel_po = this.details.fuel_po;
					this.dataParams.booking_id = this.details.id;
					if(this.details.vehicle){
						// if(this.details.vehicle.odometer.length > 0){
						// 	this.bookingParams.odometer = this.details.vehicle.odometer[this.details.vehicle.odometer.length-1].reading
						// }

						this.getOdometerData(this.details.vehicle.id)
						
					}
				}
			},
			showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}
		},
		methods: {

			confirm(){
				this.$showLoading(true)
				this.message = ""
				this.bookingParams.user_id = config.getUserDetail('id');
				this.bookingParams.status_update = true;
				var temp_date = this.bookingParams.dispatch_datetime;
				
				this.bookingParams.temp_date = moment(temp_date).format('MM/DD/YYYY HH:mm');
				this.bookingParams.dispatch_datetime = moment(this.bookingParams.dispatch_datetime, "MM/DD/YYYY HH:mm").valueOf() / 1000;
				// axios.post(config.api_path+'/bookingStatusDetail', this.dataParams)
				// .then(response=>{ 

					this.bookingParams.status = "dispatched";
					axios.put(config.api_path+'/booking/'+this.details.id, this.bookingParams)
					.then(response=>{
						this.$showLoading(false)
						if(response.data.status == "error"){
							this.message = response.data.message; 
						}
						else{
							var text = 'Booking successfully updated status to dispatched!';
			    			Swal.fire({
								title: 'Success!',
								text,
								icon: 'success', 
							})
							.then(response=>{
								this.$emit('dismissModal', 'dispatch')
							})
						}
					})
					

				// })
				
			},
			cancel(){ 
				console.log('canceleled')
				this.$emit('dismissModal', 'dispatch') 
			},
			getOdometerData(i){
		// this.data_loading.odometer = true;
				axios.get(`${config.api_path}/vehicle/odometer/${i}`).then(res => {
					const {data, status} = res.data;
					const odo = data?.odo_reading ?? "0";

					this.$set(this.bookingParams,'odometer', odo);
					this.odometer_value=odo;
					// this.data_loading.odometer = false;
				})
				.finally(fin => {
					// this.data_loading.odometer = false;
				})
			
	    },

		}

	}
</script>
<style scoped>
.btn:focus,.btn:active {
   outline: none !important;
   box-shadow: none;
}
</style>